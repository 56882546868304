import React from "react";
import jindra from "../../static/images/customers/jindra.webp"
import renata from "../../static/images/customers/renata.webp"
import karolina from "../../static/images/customers/karolina.webp"
import anicka from "../../static/images/customers/anicka.webp"
import martin from "../../static/images/customers/martin.webp"
import dominik from "../../static/images/customers/dominik.webp"
import barbora from "../../static/images/customers/bara.webp"
import sara from "../../static/images/customers/sara.webp"
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

const Reference = () => {
  const data = [
    {
      name: "Karolína Sirůčková",
      text: "Mooc děkujeme za krásné vánoční fotky, Martin to skvěle zvládl i s docela dost nespolupracujícím dítětem 😃👍",
      imgSrc: karolina,
    },
    {
      name: "Renáta Lumpová",
      text: "Moc děkujeme Martinovi za zvěčnění našeho velkého dne, lepšího kameramana jsme nemohli vybrat. Zachytil dokonale každou část naši svatby. Můžeme jen a jen doporučit ! ❤️",
      imgSrc: renata,
    },
    {
      name: "Anna Netíková ",
      text: "Ještě jednou moc děkujeme za skvělé vánoční focení👍🏼 Vše proběhlo v pohodové atmosféře, a výsledek byl parádní! Jsme moc spokojeni, a mohu jen doporučit!😊",
      imgSrc: anicka,
    },
    {
      name: "Jindra Václavek",
      text: "Tímto bychom chtěli Martinovi ještě jednou poděkovat za práci, kterou odvedl na naší svatbě. Lepšího kameramana jsme si ani vybrat nemohli. Video, které udělal, je prostě dokonalé! Moc se nám líbil jeho profesionální a kamarádský přístup. Ani jsme o něm na svatbě nevěděli a při domluvě a organizaci nám vyšel ve všem vstříc. Za to mu patří ještě jednou obrovské DÍKY!!!",
      imgSrc: jindra,
    },
    {
      name: "Martin Sigmund",
      text: "Děkujeme za natočení našeho svatebního dne. Martina můžeme jen doporučit. Celý den proběhl bez problémů, s úsměvem, profesionálně a i všechny naše požadavky na videa byly splněny 🙂",
      imgSrc: martin,
    },
    {
      name: "Dominik Maslák",
      text: "Děkujeme za skvělé video z naši svatby. S Martinem byla super spolupráce a při natáčení byla sranda. Nemáme rádi nepřirozené pózování, což ale s Martinem nehrozí. Bezproblémová domluva na všem, co jsme si přáli. Dokonce stihl udělat i pár momentek v kvalitě jako od fotografa. Všem doporučujeme.",
      imgSrc: dominik,
    },
    {
      name: "Barbora Beranová",
      text: "Díky Martinovi máme živou vzpomínku na celý život. Krásně zachytil naši svatbu ve videu. Škoda jen, že není delší 😁 Hrozně rychle uteče svatba i video 😁 Ale super práce i komunikace když chcete něco změnit, přidat či vyměnit není nic problém 🙂 Takže rozhodně doporučujeme 👍",
      imgSrc: barbora,
    },
    {
      name: "Sára Grossová",
      text: "pan Martin je moc šikovný nejen kameraman, ale i fotograf. Natočil nám nejen krásné video ze svatby, ale i nás  mezitím velmi hezky vyfotil a zachytil krásné a jedinečné momenty 👰🤵   📷 příjemná a rychlá  komunikace  📽 spolehlivost  📷 super cena = výborná kvalita  📽 profesionalita   ✅ za nás velká spokojenost, určitě doporučujeme❗",
      imgSrc: sara,
    },
  ];

  return (
    <Layout>
              <Helmet>
          <title>Reference | Martin Petrovický | Foto a video</title>
          <meta name="description" content="Zabívám se tvorbou videii a focením všech možných událostí. Na mých stránkách si můžete prohlednou ukázku mé tvorby, přečíst reference či mě rovnou kontaktovat."></meta>
      </Helmet>
      <div>
        <div className="container my-5">
          <div className="text-center h1 mb-5">Reference</div>
          <hr />
          {data.map((item, index) => {
            return (
              <div key={index}>
                <div className="row">
                  <div className="col-12 col-lg-2 text-center">
                    <img
                      src={item.imgSrc}
                      className="rounded-circle"
                      alt={"recenze od " + item.name}
                    />
                  </div>
                  <div className="col-12 col-lg-10">
                    <p className="mt-3">{item.text}</p>
                    <p className="text-end mr-2 mt-4">- {item.name}</p>
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Reference;
